.uppy-ProgressBar {
  /* no important */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 10000;
  transition: height .2s; }

.uppy-ProgressBar[aria-hidden=true] {
  /* no important */
  height: 0; }

.uppy-ProgressBar-inner {
  /* no important */
  background-color: #2275d7;
  box-shadow: 0 0 10px rgba(34, 117, 215, 0.7);
  height: 100%;
  width: 0;
  transition: width 0.4s ease; }

.uppy-ProgressBar-percentage {
  /* no important */
  display: none;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff; }
